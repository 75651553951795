import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import {useTokenContract,} from "./useContract";
import {BigNumber} from "ethers/lib/ethers";
import { MAX_APPROVAL_AMOUNT } from '../constants/addresses'


const useTokenApproves = (tokenAddress:string, x20Address:string) => {
  const { provider, connector, account, } = useWeb3React()
  const token = useTokenContract(tokenAddress);
  const handleDomainCommit = useCallback(async (amount? : string) => {
      token?.approve(x20Address,   BigNumber.from(amount ? amount :MAX_APPROVAL_AMOUNT))
    },
    [account, connector, provider, token, tokenAddress, x20Address,],
  );
  return { doApproves: handleDomainCommit };
};

export default useTokenApproves;




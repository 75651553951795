import React, { useState, useEffect } from "react";

import "./PubTokenItem.css";

import { Progress } from "antd";
import { base, world,eth2 } from "../../../assets/index";
import {NavLink} from "react-router-dom";
import { formatCurrency2 } from '../../../utils/formatBalance'

// @ts-ignore
const PubTokenItem = ({ item }) => {
    return (
      <NavLink to={'/info?token=' + item.token} className="list_item">
        <div className="netImgDiv">
          <img className="net-image" src={item.chain === 'WORLD' ? world : item.chain === 'BASE' ? base : eth2} alt="" />
        </div>
          <div className="imgDiv">
            <div className="img rounded-full">
              <img src={item.image} alt="" />
              {/*<div className="increase">+{item.increase}%</div>*/}
            </div>
          </div>
          <div className="info">
            <div className="base-info">
              <h1 className="name"> {item.name}&nbsp;({item.symbol}) </h1>
              { item.price ?<h1 className="price">{formatCurrency2(item.price)}</h1>  : <div/>}
            </div>
            <p className="desc">{item.desc}</p>
            <div className="marketcap">
              <div className="price">
                {(item.userTotal + '/' + 100000)}
              </div>

              <div className="">
                <Progress
                  percent={((item.userTotal / 100000) * 100 < 3) ? 3 : ((item.userTotal / 100000) * 100)}
                  showInfo={false}
                  strokeColor={{
                    from: '#A020F0',
                    to: '#A020F0'
                  }}
                  trailColor="#C0C0C0"
                />
              </div>
            </div>
          </div>
      </NavLink>
    );
};
export default PubTokenItem;

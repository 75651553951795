import {Input, Pagination, Table} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import './PubTokenInfo.css'
import useInterval from '../../../hooks/useInterval'
import {useWeb3React} from "@web3-react/core";
import {useX20Factory, useX20Token} from "../../../hooks/useContract";
import { getUrlParamParam, getUrlParams } from '../../../utils'

const { TextArea } = Input;
const { Column } = Table;
const PubTokenLog = () => {
  const { account } = useWeb3React()
  const API_SERVER = process.env.REACT_APP_API_SERVER;

  const [tokenInfo, setTokenInfo] = useState({id:0, name:"", symboy:"", token: "", image: ""});
  const [tokenAddress, setTokenAddress] = useState('');

  useEffect(() => {
    var token = getUrlParams()['token'];
    // const token = getUrlParamParam('log');
    console.info("Token ==>" + token);
    setTokenAddress(token);
    readTokenInfo(token).then(r => {
      console.info('read token succ');
    });
  }, [setTokenInfo, setTokenAddress])

  const readTokenInfo = async (token: string) => {
    const list = await fetch(API_SERVER + '/v2api/x20/tokenInfo?token=' + token)
    const resList = await list.json()
    if (resList['code'] === 200) {
      setTokenInfo(resList['data']);
    }
  };

  const [x20JoinUserList, setX20JoinUserList] = useState([]);


  const [currentX20, setCurrentX20] = useState(1);
  const [totalX20, setTotalX20] = useState(0)
  const onChangeX20 = (page: any) => {
    setCurrentX20(page);
  };

  const fetchLastTime = useCallback(async () => {
    try{
      const list = await fetch(API_SERVER + '/v2api/x20/walletLog?projectId='
          + tokenInfo.token +"&address=" + account + "&logPage=" + 0 + "&projPage=" + currentX20)
      const resList = await list.json()
      if (resList['code'] === 200) {
        const listRow = resList['data'];
        if(listRow){
          setX20JoinUserList(listRow['userList']);
          setTotalX20(listRow['userTotal']);
        }
      }
    }catch (e) {
      console.error(e);
    }
  }, [  account, tokenInfo,  currentX20,  setTokenAddress, setX20JoinUserList, setTotalX20,]);
  useInterval(fetchLastTime);

  return (
    <div className="PubTokenInfoDiv">

      <div className="history">
        {/*<div className="type-title">Join User List</div>*/}
        <div className="table-list">
          <Table id="domainTable" dataSource={x20JoinUserList} pagination={false} >
            <Column title="Order" dataIndex="orders" key="orders"/>
            <Column title="address" dataIndex="address" key="address"
                    render={(_, record: any) => (
                        <div className="table_user">
                          {record.address}
                        </div>
                    )}
            />
            <Column title="Time" dataIndex="createTime" key="createTime"/>
          </Table>
        </div>
        <div className="pageDiv">
          <Pagination
              total={totalX20}
              defaultPageSize={10}
              defaultCurrent={1}
              current={currentX20}
              onChange={onChangeX20}
              showSizeChanger={false}
          />
        </div>
      </div>

    </div>
  )
}
export default PubTokenLog

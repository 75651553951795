import { Input, message, Upload } from 'antd'
import React, { useCallback, useState } from 'react'
// import {useWeb3React} from '@web3-react/core'
import './PubToken.css'
// import {useCryptoToken, useX20Factory,} from '../../../hooks/useContract';
import { useWeb3React } from '@web3-react/core'
import { useX20Factory } from '../../../hooks/useContract'
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import TransactionConfirmationModal, { TransactionErrorContent } from '../../../components/TransactionConfirmationModal'
import SwappingModal from '../../../components/FeatureFlagModal/SwappingModal'

const { TextArea } = Input;
const PubProfile = (props: any) => {
    const { account,provider } = useWeb3React()
    const API_SERVER = process.env.REACT_APP_API_SERVER;


    const [myToken, setMyToken] = useState('');

    const x20Factory = useX20Factory();
    // const x20Token = useX20Token(myToken);
    // const baseToken = useBaseToken();

    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0)
    const onChange = (page: any) => {
        setCurrent(page);
    };

    const [currentLog, setCurrentLog] = useState(1);
    const [totalLog, setTotalLog] = useState(0)
    const onChangeLog = (page: any) => {
        setCurrentLog(page);
    };

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const uploadButton = (
      <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setLoading(false);
            setImageUrl(info.file.response.data);
            // getBase64(info.file.originFileObj as RcFile, url => {
            //     setLoading(false);
            //     setImageUrl(url);
            // });
        }
    };

    const [tokenName, setTokenName] = useState("");
    // @ts-ignore
    const tokenNameChange = ({ target: { value } }) => {
        setTokenName(value);
    };

    const [tokenSymbol, setTokenSymbol] = useState("");
    // @ts-ignore
    const tokenSymbolChange = ({ target: { value } }) => {
        setTokenSymbol(value);
    };
    const [tokenDesc, setTokenDesc] = useState("");
    // @ts-ignore
    const tokenDescChange = ({ target: { value } }) => {
        setTokenDesc(value);
    };

    const [website, setWebsite] = useState("");
    // @ts-ignore
    const websiteChange = ({ target: { value } }) => {
        setWebsite(value);
    };

    const [warpcaster, setWarpcaster] = useState("");
    // @ts-ignore
    const warpcasterChange = ({ target: { value } }) => {
        setWarpcaster(value);
    };

    const [telegram, setTelegram] = useState("");
    // @ts-ignore
    const telegramChange = ({ target: { value } }) => {
        setTelegram(value);
    };
    const [twitter, setTwitter] = useState("");

    // @ts-ignore
    const twitterChange = ({ target: { value } }) => {
        setTwitter(value);
    };

    const [txHash, setTxHash] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [hashConformed, setHashConformed] = useState(false);
    const onModalDismiss = useCallback(async () => {
        setOpen(false);
    }, [account, isOpen, x20Factory, setOpen])

    const onDismiss = ()=>{
        setOpen(false);
    }

    const confirmationContent = useCallback(
      () => <TransactionErrorContent onDismiss={onModalDismiss} message={'error message'} />,
      [onModalDismiss,]
    )

    const doCreateToken = useCallback(() => {
        let isCancelled = false;
        const fetchDataWithDelay = async () => {
            if(!x20Factory)return;
            const x =  await x20Factory.createToken(tokenName, tokenSymbol, imageUrl, tokenDesc, website, warpcaster, telegram, twitter);
            console.log('create token hash=> ' + x.hash);
            const hash = x.hash;
            // const hash = '0x55c531ba6cbd1543fad17e29d47b72d844a3248ca73bfa5495d59d3d493ff780';
            setTxHash(hash);
            setOpen(true);

            while (true) {
                const result = await fetchHashState(hash);
                if (result) {
                    const newToken = await x20Factory.userLastToken(account);
                    console.log('newToken=' + newToken);
                    setMyToken(newToken);
                    // setOpen(false);
                    setHashConformed(true);
                    break;
                }
                await delay(2000);
                if (isCancelled) {
                    break;
                }
            }
        };
        fetchDataWithDelay();
        return () => {
            isCancelled = true;
        };
    }, [x20Factory,  tokenName, tokenSymbol, tokenDesc, imageUrl, tokenDesc, website, warpcaster, telegram, twitter, account, setMyToken, setTxHash, setOpen, setHashConformed]);

    // @ts-ignore
    const fetchHashState = async (hash: string) => {
        try{
            if(provider){
                const hashRes = await provider.getTransactionReceipt(hash);
                return hashRes.status;
            }
        }catch(err){
            console.error(err);
        }
        return undefined;
    };
    const delay = (ms:number) => new Promise(resolve => setTimeout(resolve, ms));



    return (
      <>
          <div className="PubToken">

              {/*<div>txHash={txHash}</div>
                <div>myToken={myToken}</div>*/}
              {/*<TwtLogin project={"twitter"}/>*/}

              <div className="mutirow">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="left avatar-uploader"
                    showUploadList={false}
                    action={API_SERVER + '/v2api/x20/uploadImage'}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                      {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                  </Upload>

                  <div className="right">
                      <div className="linerow">
                          <div className="towtitle">Token Name</div>
                          <div className="rowinput">
                              <Input
                                showCount maxLength={20}
                                value={tokenName}
                                onChange={tokenNameChange}
                                disabled={myToken != ''}
                              />
                          </div>
                      </div>

                      <div className="linerow linerowmt">
                          <div className="towtitle">Token Symbol ($Ticker) *</div>
                          <div className="rowinput">
                              <Input
                                showCount maxLength={10}
                                value={tokenSymbol}
                                onChange={tokenSymbolChange}
                                disabled={myToken != ''}
                              />
                          </div>
                      </div>
                  </div>
              </div>


              <div className="linerow linerowmt">
                  <div className="towtitle">Token Description *</div>
                  <div className="rowinput">
                      <TextArea showCount maxLength={300} rows={4} value={tokenDesc} onChange={tokenDescChange} disabled={myToken != ''}
                                placeholder="Please Input Token Description"/>
                  </div>
              </div>

              <div className="linerow linerowmt">
                  <div className="towtitle">Website</div>
                  <div className="rowinput">
                      <Input
                        value={website}
                        onChange={websiteChange}
                        placeholder="Optional"
                        disabled={myToken != ''}
                      />
                  </div>
              </div>
              <div className="linerow linerowmt">
                  <div className="towtitle">Warpcast</div>
                  <div className="rowinput">
                      <Input
                        value={warpcaster}
                        onChange={warpcasterChange}
                        placeholder="Optional"
                        disabled={myToken != ''}
                      />
                  </div>
              </div>
              <div className="linerow linerowmt">
                  <div className="towtitle">Telegram</div>
                  <div className="rowinput">
                      <Input
                        value={telegram}
                        onChange={telegramChange}
                        placeholder="Optional"
                        disabled={myToken != ''}
                      />
                  </div>
              </div>
              <div className="linerow linerowmt">
                  <div className="towtitle">Twitter</div>
                  <div className="rowinput">
                      <Input
                        value={twitter}
                        onChange={twitterChange}
                        placeholder="Optional"
                        disabled={myToken != ''}
                      />
                  </div>
              </div>

              {
                  myToken === '' ? <div onClick={doCreateToken} className={'x20_comm_btn pub_btn'}>Create Token</div>
                    : <NavLink to={'/info?token=' + myToken}>
                        <div className={'x20_comm_btn pub_btn'}>Go to the page of the token I created</div>
                    </NavLink>
              }

              <SwappingModal open={isOpen} close={() => onModalDismiss()} hash={txHash}  back={onModalDismiss}/>

              {/*<TransactionConfirmationModal
                isOpen={isOpen}
                onDismiss={onModalDismiss}
                attemptingTxn={true}
                hash={txHash}
                content={confirmationContent}
                finish={hashConformed}
                pendingText={''}
              />*/}

          </div>
      </>
    )
}
export default PubProfile

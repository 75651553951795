import {message, Modal, Input, Pagination, Table, Image} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import './TweetInfo.css'
import '../TweetAll.css'
import {
  close,
  DOG_HOST_URL,
  IS_TEST,
  MAIN_TAG,
  NULL_ADDRESS,
  TW_LOGIN_URL
} from "../../../constants/addresses";
import useInterval from '../../../hooks/useInterval'
import logo from "../../../assets/img/logo.png";
import eth_location from "../../../assets/svg/eth_location.svg";
import searchIcon from "../../Ens/Search/search.svg";
import {formatAddress} from "../../../utils/time";
import {NavLink} from "react-router-dom";
import TweetLogin from "../TweetLogin";
import useTokenApprove from "../../../hooks/useTokenApprove";
import { useBaseToken, useX20Pool, useX20Token } from '../../../hooks/useContract'
import {getBalanceNumber, getDisplayBalance} from "../../../utils/formatBalance";
import {BigNumber} from "bignumber.js";
const { TextArea } = Input;
const { Column } = Table;
const EnsRegist = (props) => {
  const { account, provider } = useWeb3React()
  const [twAccount, setTwAccount] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');

  useEffect(() => {
    let urlString = window.location.hash
    let tempParam = urlString.substring(urlString.indexOf('/') + 1)
    if (tempParam.indexOf('?') > 0) {
      tempParam = tempParam.substring(2, tempParam.indexOf('?'));
    }
    tempParam = tempParam.replaceAll("x/", "");
    setTwAccount(tempParam)
  }, [setTwAccount, ])
  console.info('twAccount=' + twAccount);

  const [ensList, setEnsList] = useState([]);
  const [isProjectPub, setIsProjectPub] = useState(false);
  const [projectUserList, setProjectUserList] = useState([]);
  const [myJoinList, setMyJoinList] = useState([]);
  const [x20JoinUserList, setX20JoinUserList] = useState([]);

  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(200)
  const onChange = (page) => {
    setCurrent(page);
  };

  const [domainName, setDomainName] = useState("");
  const domainNameChange = ({ target: { value } }) => {
    let checkedName = value.toString().toLowerCase().replace(" ", "");
    setDomainName(checkedName);
  };
  const [loginUserInfo, setLoginUserInfo] = useState(IS_TEST
      ? {login: true, isReged: false, activeScore: false, eth:'xxx.eth', avatar:'https://pbs.twimg.com/profile_images/1644851643228516353/-adFBedl_normal.jpg', nickName:'nickname', userName:'username', address:'xxxxxxxxxx', }
      : {login: false, isReged: false, activeScore: false, eth:'', avatar:'', nickName:'', userName:'', address:'', }
  );
  const [projectInfo, setProjectInfo] = useState(IS_TEST
      ?{login: true, isReged: false, eth:'',
        avatar:'https://pbs.twimg.com/profile_images/1644851643228516353/-adFBedl_normal.jpg',
        nickName:'nickname', userName:'username', address:'', token:'',
      }
      : {login: true, isReged: false, eth:'', avatar:'', nickName:'', userName:'', address:'',  token:''});

  const getLoginUserInfo = useCallback(async () => {
    const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
    console.info('twToken=' + twToken);
    if(!twToken)return;
    if(IS_TEST)return;
    try{
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/getUserInfo?token=' + twToken.toString() + '&projectId=' + twAccount)
      const resList = await list.json()
      if (resList['code'] === 200) {
        const info = resList['data'];
        setLoginUserInfo({login: true, isReged: info.reged, activeScore:info.activeScore, eth: info.eth, avatar:info.avatar, nickName:info.nickName, userName:info.userName, address:info.address});
      }else{
        setLoginUserInfo({login: false, isReged: false, activeScore:'0',eth:'', avatar:'', nickName:'', userName:'', address:'', });
      }
    }catch (e) {
      console.error(e);
      setLoginUserInfo({login: false, isReged: false, activeScore:'0',eth:'',avatar:'', nickName:'', userName:'', address:'', });
    }
  }, [setLoginUserInfo,twAccount]);

  const getProjectInfo = useCallback(async () => {
    try{
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/projectInfo?projectId=' + twAccount)
      const resList = await list.json()
      if (resList['code'] === 200) {
        const listRow = resList['data'];
        setProjectUserList(listRow['scoreList']);
        setIsProjectPub(listRow['isLogin']);
        setProjectInfo(listRow['projInfo']);
        const userTokenAddress = listRow['projInfo']['token'];
        console.info('userTokenAddress=' + userTokenAddress);
        setTokenAddress(userTokenAddress);
        setX20JoinUserList(listRow['x20JoinUserList']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [twAccount, setProjectUserList, setIsProjectPub, setProjectInfo, setTokenAddress, setX20JoinUserList]);

  // const getMyJoinInfo = useCallback(async () => {
  //   const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
  //   console.info('twToken=' + twToken);
  //   if(!twToken)return;
  //   try{
  //     const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/myAccount?userId=1')
  //     // const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/myAccount?token=' + twToken)
  //     const resList = await list.json()
  //     if (resList['code'] === 200) {
  //       const listRow = resList['data']['tweetScoreList'];
  //       setMyJoinList(listRow);
  //     }
  //   }catch (e) {
  //     console.error(e);
  //   }
  // }, [setMyJoinList,]);


  const fetchLastTime = useCallback(async () => {
    try {
      getLoginUserInfo();
      getProjectInfo();
      // getMyJoinInfo();
    } catch (e) {
      console.log(e);
    }
  }, [account, setEnsList, twAccount, domainName, setProjectUserList, setMyJoinList]);
  useInterval(fetchLastTime, 3000);


  const [isFirstSend, setIsFirstSend] = useState(0);//首次激活不要带链接0,
  const [goonVisible, setGoonVisible] = useState(false);
  const showRule = (e) => {
    setGoonVisible(true);
    setIsFirstSend(e);
  };
  const cancelRule = () => {
    setGoonVisible(false);
  };

  const [messageContent, setMessageContent] = useState("");
  const messageContentChange = ({ target: { value } }) => {
    setMessageContent(value);
  };


  const sendTwitterMsg = useCallback(async () => {
    try{
      const twToken = localStorage.getItem("DOG-TW-LOGIN-KEY");
      console.info('twToken=' + twToken);
      if(!twToken){
        return message.success('login twitter first');
      }
      const list = await fetch(DOG_HOST_URL + '/ethscription/twitter/sendMsg?message=' + messageContent.toString() + "&scoreName=" + (isFirstSend === 0 ? twAccount : 'SELF') + "&token=" + twToken + '&type=' + isFirstSend)
      const resList = await list.json()
      if (resList['code'] === 200) {
        message.success('send sucess');
        cancelRule();
      }else{
        message.error(resList['msg']);
      }
    }catch (e) {
      console.error(e);
    }
  }, [isFirstSend, twAccount, messageContent,]);



  //Mint
  const isDidOpen = true;
  const [dataState, setDataState] = useState(false);

  const [canMintToken, setCanMintToken] = useState(false);
  const [needAproveBaseToken, setNeedAproveBaseToken] = useState(true);
  const [tokenName, setTokenName] = useState('');
  const [tokenImage, setTokenImage] = useState();
  const [baseTokenName, setBaseTokenName] = useState('CRYPTO');
  const [baseTokenAmount, setBaseTokenAmount] = useState('');
  const [baseTokenAddr, setBaseTokenAddr] = useState('');
  const [inviter, setInviter] = useState(NULL_ADDRESS);
  const [poolManagerAddr, setPoolManagerAddr] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [userWithdraw, setUserWithdraw] = useState(0);


  const x20Token = useX20Token(tokenAddress);
  const baseToken = useBaseToken();
  const x20Pool = useX20Pool(poolManagerAddr);

  const readInfos = useCallback(async () => {
    if(!account)return;
    try{
      if(x20Token){
        console.info('tokenAddress=' + tokenAddress);
        const poolManager = await x20Token.poolManager();
        console.info('poolManager=' + poolManager);
        setPoolManagerAddr(poolManager);
        setBaseTokenAddr(await x20Token.baseToken());
        setTokenName(await x20Token.symbol());
        setTokenImage(await x20Token.image());
        setCanMintToken((await x20Token.userMintStatus(account)).toString() === 'false');

        const baseDecimals = 18;
        //parseInt((await baseToken.decimals()).toString());
        // setBaseTokenName(await baseToken.symbol());

        const baseNum =  (await x20Token.cryptoAmount()).toString();
        const baseAmount = getBalanceNumber(new BigNumber(baseNum), baseDecimals);
        const baseAllowance = getBalanceNumber(new BigNumber((await baseToken.allowance(account, tokenAddress)).toString()), baseDecimals);
        setNeedAproveBaseToken(parseFloat(baseAmount.toString()) > parseFloat(baseAllowance.toString()));
        console.info('baseAllowance=' + baseAllowance + '   | baseDecimals=' + baseDecimals + " |baseAmount=" + baseAmount);
        setBaseTokenAmount(baseAmount.toString());

        const viewAvailableTokens = getBalanceNumber(new BigNumber((await x20Pool.viewAvailableTokens(account)).toString()));
        const viewAvailableInvite = getBalanceNumber(new BigNumber((await x20Pool.viewAvailableInvite(account)).toString()));
        const availableTotal = getDisplayBalance(new BigNumber((await x20Pool.availableTotal(account)).toString()));
        const userWithdraw = getDisplayBalance(new BigNumber((await x20Pool.userWithdraw(account)).toString()));

        console.info('viewAvailableTokens=' + viewAvailableTokens);
        console.info('viewAvailableInvite=' + viewAvailableInvite);
        console.info('initLibTimesNow=' + (await x20Token.initLibTimesNow()).toString());

        setWithdrawAmount(parseFloat(availableTotal.toString()))
        setUserWithdraw(parseFloat(userWithdraw.toString()))
        setDataState(true);
      }
    }catch (e) {
      console.error(e);
    }
  }, [account, tokenAddress, x20Token, baseToken, x20Pool,
    setPoolManagerAddr,setBaseTokenAddr,setTokenName, setTokenImage,
    setNeedAproveBaseToken,setBaseTokenName,setCanMintToken,
    setBaseTokenAmount,setWithdrawAmount,setUserWithdraw, setDataState]);

  useInterval(readInfos, 1000);

  const {doApprove} = useTokenApprove(baseTokenAddr, tokenAddress);

  const doMintToken = useCallback(async () => {
    if(!account){return message.error('symbol can not be empty');}
    if(!canMintToken)return;
    x20Token.userMint(inviter);
  }, [ x20Token, account, inviter, canMintToken]);

  return (
    <div className="TweetInfoArea">

      <TweetLogin/>

      <div className="login-info">
        {
          !loginUserInfo.login ?
              <div className="unlogin">
              </div>
              :(
                  <div className="project-divs">
                    <div className="user-area">
                      <div className="user-info">
                        <div className="row-left">
                          <img className="user-avater rounded-full" src={projectInfo.avatar} alt="" />
                          <div className="user-infos">
                            <div className="left-info">
                              <div className="title">{projectInfo.nickName}</div>
                              <div className="title">@{projectInfo.userName}</div>
                            </div>

                            <div className="right-info">
                              <div>
                                {
                                  loginUserInfo.isReged ? <div/> :
                                  <button onClick={() => showRule(0)} className="send_twitter_btn marginLeft" type="submit" data-testid="home-search-button">First activation</button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          projectInfo.address ? <div className="row-address">{formatAddress(projectInfo.address)}</div> : <div/>
                        }

                        {
                          projectInfo && projectInfo.eth ?
                              <div className="eth-area">
                                <img src={eth_location} alt="" />
                                <NavLink className="eth-name" id={`vote-nav-link2`} to={'/MyLands'}>{projectInfo.eth}</NavLink>
                                {/*<p className="proj-total">{'Total '+(projectInfo.isReged ? 'Points' : 'Votes')  +': '+ projectInfo.mintTotal}</p>*/}
                              </div>
                              : <div/>

                              // <div className="eth-area"><p>{'Total '+(projectInfo.isReged ? 'Points' : 'Votes') +': '+ projectInfo.mintTotal}</p> </div>
                        }



                      </div>

                      <div className="user-info right-info">
                      </div>
                    </div>

                  </div>

              )
        }
      </div>

      <div className="fast-border">
        <div className="fast-item">
          <div className="box_logo">
            <img className="rounded-full" src={tokenImage ? tokenImage : logo} alt="" />
          </div>
          <p className="text">挖矿积分</p>
          <p className="text">To mine</p>
          {
            needAproveBaseToken ? (
                <div>
                  <div className="text">The Amount You Need Pay:</div>
                  <div className="text">{baseTokenAmount} {baseTokenName}</div>
                  <div className="text" onClick={doApprove} >
                    <div className={isDidOpen ? 'ens_handle_btn' : 'ens_handle_btn_disable'}>Approve</div>
                  </div>
                </div>
            ) : (
                <div className="do-btn" onClick={doMintToken} >
                  <div className={canMintToken ? 'btn ens_handle_btn' : 'ens_handle_btn_disable'}>MINE</div>
                </div>
            )
          }
        </div>

        <div className="fast-space"></div>

        <div className="fast-item">
          <div className="box_logo">
            <img className="rounded-full" src={tokenImage ? tokenImage : logo} alt="" />
          </div>

          <p className="text">社区积分</p>
          <p className="text">可领数量:340</p>
          {
            <div className="do-btn" onClick={doMintToken} >
              <div className={!canMintToken ? 'ens_handle_btn' : 'ens_handle_btn_disable'}>领取积分</div>
            </div>
          }
        </div>


      </div>


      <div className="history">
        <div className="type-title">挖矿用户列表</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={x20JoinUserList} pagination={false} >
            <Column title="User" dataIndex="avatar" key="avatar"
                    render={(_, record) => (
                        <div className="table_user">
                          <Image
                              width={20}
                              className="rule_img"
                              src={record.avatar}
                          />
                          {record.nickName}
                        </div>
                    )}
            />
            <Column title="address" dataIndex="address" key="address"/>
            <Column title="Join Time" dataIndex="createTime" key="createTime"/>
          </Table>
        </div>
        <div className="pageDiv">
          <Pagination
              total={total}
              defaultPageSize={10}
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              showSizeChanger={false}
          />
        </div>
      </div>

      <div className="history">
        <div className="type-title">社区积分领取用户列表</div>
        <div className="table-list">
          <Table id="domainTable" dataSource={projectUserList} pagination={false} >
            <Column title="User" dataIndex="avatar" key="avatar"
                    render={(_, record) => (
                        <div className="table_user">
                          <Image
                              width={20}
                              className="rule_img"
                              src={record.avatar}
                          />
                          {record.nickName}
                        </div>
                    )}
            />
            <Column title="Get Amount" dataIndex="avatar" key="avatar"
                    render={(_, record) => (
                        <div className="table_amount">
                          {record.mintTotal}
                        </div>
                    )}
            />
            <Column title="address" dataIndex="address" key="address"/>
            {/*<Column title="Symbol" dataIndex="nickName" key="nickName"/>*/}
            <Column title="Join Time" dataIndex="createTime" key="createTime"/>
          </Table>
        </div>
        <div className="pageDiv">
          <Pagination
              total={total}
              defaultPageSize={10}
              defaultCurrent={1}
              current={current}
              onChange={onChange}
          />
        </div>
      </div>

      {/*<div className="fast-border">
        <div className="fast-item">
          <div className="user-row-title">
            <div>Join Users</div>
          </div>
          {x20JoinUserList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                    </div>
                  </div>
                </div>
            );
          })}

          <div className="pageDiv">
            <Pagination
                total={total}
                defaultPageSize={10}
                defaultCurrent={1}
                current={current}
                onChange={onChange}
            />
          </div>
        </div>

        <div className="fast-space"></div>
        <div className="fast-item">
          <div className="user-row-title">
            <div> {twAccount} Top Holders</div>
            <div>Votes/Points</div>
          </div>
          {projectUserList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.mintTotal}
                  </div>
                </div>
            );
          })}
        </div>

        <div className="fast-item">
          <div className="user-row-title">
            <div>Your Balance</div>
            <div>Amount</div>
          </div>
          {myJoinList.map((item, i) => {
            return (
                <div className="user-row" key={i}>
                  <div className="row-left">
                    <img className="user-avater rounded-full" src={item.avatar} alt="" />
                    <div className="left-info">
                      <div className="title">{item.nickName}</div>
                      <div className="title">@{item.userName}</div>
                      <div className="title">Holders {item.userTotal}</div>
                    </div>
                  </div>
                  <div className="row-right">
                    {item.balance}
                  </div>
                </div>
            );
          })}
        </div>
      </div>*/}


      <Modal
          visible={goonVisible}
          onCancel={cancelRule}
          closable={false}
          centered={true}
          footer={null}
          className="rule_modal_box"
      >
        <div className="title tit">
          Send the post on X
        </div>

        <div className="send_tw_dialog">
          <div className="search-row">
            Contents:
            <TextArea
                rows={4}
                maxLength={300}
                height={500}
                placeholder="Input Message Contents"
                autoCapitalize="off"
                className="search-form search-form-area"
                value={messageContent}
                onChange={messageContentChange}
            />
          </div>
          <div className="search-row">
            Add Tags:
            <form className="search-form" action="#">
              <input
                  placeholder="Tags"
                  autoCapitalize="off"
                  className="modal_input"
                  disabled="disabled"
                  value={"#" + MAIN_TAG +" #" + (isFirstSend === 0 ? twAccount : loginUserInfo.userName)}
              />
            </form>
          </div>
          {isFirstSend === 0 ?
          <div/> :
              <div className="search-row">
                My username points page:
                <form className="search-form" action="#">
                  <input
                      placeholder="Tags"
                      autoCapitalize="off"
                      className="modal_input"
                      disabled="disabled"
                      value={DOG_HOST_URL + '/#/x/' + loginUserInfo.userName}
                  />
                </form>
              </div>
          }
          <div className="search-text">
            {
              isFirstSend===0 ? 'After the first activation, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again. '
                  : 'After creating skyrocketing influence engine, you can post a post with 2 tags attached on X to earn the username points without having to post on this website again.'
            }
          </div>
        </div>
        <div  className="send_twitter_btn" onClick={sendTwitterMsg}>Send now!</div>
      </Modal>

    </div>
  )
}
export default EnsRegist

export const formatAddress = (str) => {
  if (!str) return ''
  if (str.length <= 9) {
    return str; // 如果字符串长度小于或等于9，则返回原始字符串
  }
  return str.slice(0, 5) + '...' + str.slice(-4);
}


export const formatStrShow = (str) => {
  if (!str) return ''
  // str = str.toLowerCase();
  if (str.length <= 55) {
    return str; // 如果字符串长度小于或等于9，则返回原始字符串
  }
  return str.slice(0, 40) + '......' + str.slice(-21);
}

export const formatUrl = (str) => {
  if (!str) return ''
  // str = str.toLowerCase();
  if (!str.startsWith('http')) {
    return 'http://' + str; // 如果字符串长度小于或等于9，则返回原始字符串
  }
  return str;
}


// 时间转换1
export const timestampToTime = (timestamp) => {
  timestamp = timestamp + ''
  if (!timestamp) return ''
  let date = null
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  if (timestamp.length === 10) {
    date = new Date(timestamp * 1000)
  } else if (timestamp.length === 13) {
    date = new Date(timestamp)
  }
  if(date == null) return '';
  let Y = date.getFullYear() + '-'
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  let D = zeroPadding(date.getDate()) + ' '
  let h = zeroPadding(date.getHours()) + ':'
  let m = zeroPadding(date.getMinutes()) + ':'
  let s = zeroPadding(date.getSeconds())
  return Y + M + D + h + m + s
}

export const formatToUTC= (timestampInSeconds) => {
  if (!timestampInSeconds) return ''
  const date = new Date(timestampInSeconds * 1000); // 转换为毫秒
  if(date == null) return '';
  return date.toISOString(); // 返回UTC标准格式
}

// 时间转换2
export const TimeYMD = (timestamp) => {
  timestamp = timestamp + ''
  if (!timestamp) return ''
  let date = null
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  if (timestamp.length === 10) {
    date = new Date(timestamp * 1000)
  } else if (timestamp.length === 13) {
    date = new Date(timestamp)
  }
  let Y = date.getFullYear() + '/'
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
  let D = zeroPadding(date.getDate())
  return Y + M + D
}

export const formatLastTime = (time) => {
  var hours = Math.floor(time / 3600);
  var minutes = Math.floor(Math.floor(time % 3600) / 60);
  var seconds = Math.floor(time % 60);
  var h = hours.toString().length === 1 ? `0${hours}` : hours;
  var m = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  var s = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  return `${h}:${m}`;
}

// 时间转换3
export const TimeHMS = (timestamp) => {
  timestamp = timestamp + ''
  if (!timestamp) return ''
  if (timestamp === 0) return ''
  let date = null
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  if (timestamp.length === 10) {
    date = new Date(timestamp * 1000)
  } else if (timestamp.length === 13) {
    date = new Date(timestamp)
  }
  if (!date) return ''
  let h = zeroPadding(date.getHours()) + ':'
  let m = zeroPadding(date.getMinutes()) + ':'
  let s = zeroPadding(date.getSeconds())
  return h + m + s
}

export const timestampToTime2 = (timestamp) => {
  // var minute = timestamp / 60;
  // return minute.toFixed(1) + '分钟';

  return timestamp + '秒'
}

// 补零
const zeroPadding = (t) => {
  if (t < 10) {
    return (t = '0' + t)
  } else {
    return t
  }
}

import React, { useState, useEffect, useCallback } from 'react'
import { ArrowDownOutlined, DownOutlined } from "@ant-design/icons";
import { Tooltip, Modal, message } from 'antd'
import TokenSelection from "./TokenSelection/TokenSelection";

import trade from "../../../assets/swap/trade.svg";
import arrow from "../../../assets/swap/arrow.svg";
import tips from "../../../assets/swap/tips.svg";
import setup from "../../../assets/swap/setup.svg";
import closureAsh from "../../../assets/swap/closureAsh.svg";

import "./TokenSwap.css";
import { getChainName, getSwapV2RouterAddress, useSwapV2Router, useX20Token } from '../../../hooks/useContract'
import useTokenApproves from '../../../hooks/useTokenApproves'
import { useWeb3React } from '@web3-react/core'
import { getBalanceNumber, getBalanceNumber2, getBalanceNumber3, toBigString } from '../../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import useInterval from '../../../hooks/useInterval'
import SwappingModal from '../../../components/FeatureFlagModal/SwappingModal'
import { MAX_APPROVAL_AMOUNT } from '../../../constants/addresses'
import { SupportedChainId } from '../../../constants/chains'

const TokenSwap = () => {
  const [selected, setSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [btnStatus, setBtnStatus] = useState(0);
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const [isPricing, setPricing] = useState(false);

  const [currency, setCurrency] = useState([
    {
      chain: "WORLD",
      icon: "http://0xtest2024apps001olunoshibdegenbitethusdtc0xogs891.wedegen.com/upimages/tweet/2024/10/31/63e6415b-576d-47fe-9101-9e3abbce5776.png",
      name: "WLD",
      orders: 1,
      slippage: 1.2,
      symbol: "WORLD Token",
      token: "0x2cFc85d8E48F8EAB294be644d9E25C3030863003",
    },
    {
      chain: "WORLD",
      icon: "http://0xtest2024apps001olunoshibdegenbitethusdtc0xogs891.wedegen.com/upimages/tweet/2024/10/31/63e6415b-576d-47fe-9101-9e3abbce5776.png",
      name: "BTC",
      orders: 1,
      slippage: 1.2,
      symbol: "BTC Token",
      token: "0x4ec3b7Ada5f8508CB0a27260E3002AA1b401F067",
    },
    {
      chain: "WORLD",
      icon: "http://0xtest2024apps001olunoshibdegenbitethusdtc0xogs891.wedegen.com/upimages/tweet/2024/10/31/63e6415b-576d-47fe-9101-9e3abbce5776.png",
      name: "DEG",
      orders: 1,
      slippage: 1.2,
      symbol: "DEG Token",
      token: "0x54052333be30f3f671c1a3877b8a980d35e97dcd",
    },
  ]);
  const [sellToken, setSellToken] = useState({});
  const [buyToken, setBuyToken] = useState({});

  const [sellAmount, setSellAmount] = useState('');
  const sellChange = ({ target: { value } }) => {
    setSellAmount(value);
  };

  const [buyAmount, setBuyAmount] = useState('');
  const [amountsOutMin, setAmountsOutMin] = useState('0');
  const buyChange = ({ target: { value } }) => {
    setBuyAmount(value);
  };

  const { account, chainId } = useWeb3React()
  const swapV2Router = useSwapV2Router();
  const swapV2RouterAddress = getSwapV2RouterAddress();
  const {doApproves} = useTokenApproves(sellToken.token, swapV2RouterAddress);
  const sellTokens = useX20Token(sellToken.token);
  const buyTokens = useX20Token(buyToken.token);

  const [baseAllowance, setBaseAllowance] = useState('0');
  const [baseBalance, setBaseBalance] = useState('0');

  const [showSwappingDialog, setShowSwappingDialog] = useState(false);
  const [sendSwappingHash, setSendSwappingHash] = useState('');


  function showSwappingHash(hash) {
    setSendSwappingHash(hash)
    setShowSwappingDialog(true)
  }

  function onSwappingBack() {
    setSendSwappingHash('')
    setBuyAmount('');
    setSellAmount('');
    setShowSwappingDialog(false)
  }


  const [moreInfo, setMoreInfo] = useState({
    sell: 5.6,
    sellName: "ETH",
    buy: 2.4,
    buyName: "BTC",
    value: 0.03,
    fee: 0.006,
    symbol: "1INCH",
    network: "0.03",
    routing: "Uniswap API",
    price: 0.58,
    increase: false,
    slippage: 1,
  });

  const chain = getChainName();

  const getSwapTokenList = useCallback(async (chain) => {
    if(chain){
      const res = await (
        await fetch(
          API_SERVER + "/v2api/x20/swapTokenList?chain=" + chain
        )
      ).json();
      console.log(chain + " request swap token list", res);
      if (res.code === 200 && res.data) {
        setCurrency(res.data);
      }
    }
  },[setCurrency]);

  useEffect(() => {
    let chain = '';
    if(chainId === SupportedChainId.BASE){
      chain = 'BASE';
    }else  if(chainId === SupportedChainId.MAINNET){
      chain = 'ETH';
    }else  if(chainId === SupportedChainId.ARBITRUM_ONE){
      chain = 'ARB';
    }else  if(chainId === SupportedChainId.WORLD){
      chain = 'WORLD';
    }
    console.info("getSwapTokenList,chainId=" + chainId + ", chain=" + chain);
    getSwapTokenList(chain).then((res) => {});
  }, [chainId]);

  const reviewClick = () => {
    if(isPricing){return;}
    setConfirmModal(true);
  };
  const linkWallet = () => {
    setBtnStatus(1);
  };

  const exchangeHandle = () => {
    const obj = JSON.parse(JSON.stringify(sellToken));
    setSellToken(buyToken);
    setBuyToken(obj);
    const num = sellAmount;
    setSellAmount(buyAmount);
    setBuyAmount(num);
  };

  const [maxSlippage, setMaxSlippage] = useState("1.2");
  const maxSlippageChange = ({ target: { value } }) => {
    // if(parseFloat(value) < 1.2) {
    //   value = 1.2;
    // }
    setMaxSlippage(value);
  };

  const [time, setTime] = useState("");
  const timeChange = ({ target: { value } }) => {
    setTime(value);
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const handleCancelModal = () => {
    setConfirmModal(false);
  };

  const getSellTokenInfo = useCallback(async () => {
    if(sellTokens && buyTokens && parseFloat(sellAmount) > 0){
      setPricing(true);
      const amount = toBigString(sellAmount.toString());
      const path = [sellTokens.address, buyTokens.address];

      const amountsOut = await swapV2Router.getAmountsOut(amount, path);

      const minValue = getBalanceNumber3(amountsOut[1], 1.5);
      const minShowValue = getBalanceNumber2(minValue, 18);

      console.info('normalValue==' + getBalanceNumber2(amountsOut[1], 18));
      console.info('minValue==' + minShowValue);
      setAmountsOutMin(parseFloat(minShowValue));
      setBuyAmount(minShowValue);

      setBtnStatus(2);
      setPricing(false);
    }
  }, [swapV2Router, account,  buyAmount, sellAmount, baseAllowance, baseBalance, sellToken, sellTokens, buyTokens,
    setBuyAmount, setBtnStatus, setPricing, setAmountsOutMin]);
  // useInterval(getSellTokenInfo);


  const fetchLastTime = useCallback(async () => {
    // try{
      if(account){
        if(sellTokens && swapV2RouterAddress){
          const allowance = getBalanceNumber(new BigNumber((await sellTokens.allowance(account, swapV2RouterAddress)).toString()));
          setBaseAllowance(allowance);
          const b = (await sellTokens.balanceOf(account)).toString();
          const balance = getBalanceNumber(new BigNumber(b));
          setBaseBalance(balance);
          console.info(sellTokens.address+',Allowance=' + allowance + ' ,balance=' + balance + ' ,balance2=' + b);
        }
      }
    // }catch (e) {
    //   console.error(e);
    // }
  }, [swapV2RouterAddress, account,  buyAmount, sellAmount, baseAllowance, baseBalance, sellToken, sellTokens, buyTokens,
    setBuyAmount, setBtnStatus, setPricing]);
  useInterval(fetchLastTime);




  const confirmExchange = useCallback(async () => {
    if(!account){return message.error('please connect wallet first');}
    try{
      if(parseFloat(sellAmount) <= 0){
        return message.error('The purchase quantity must be a positive number.');
      }
      if(parseFloat(sellAmount) > parseFloat(baseBalance)){
        return message.error('Insufficient token balance.');
      }
      if(parseFloat(sellAmount) > parseFloat(baseAllowance)){
        doApproves().then(r => {});
        return false;
      }
      handleCancelModal();
      console.info('start swap==>' + swapV2Router);
      if(swapV2Router){
        const amount = toBigString(sellAmount.toString());
        const path = [sellTokens.address, buyTokens.address];
        const timestampInSeconds = Math.floor(Date.now() / 1000) + 1200;
        const minValue = toBigString(amountsOutMin.toString());
        console.info('minValue==' + minValue);
        await swapV2Router.swapExactTokensForTokensSupportingFeeOnTransferTokens(amount, minValue, path, account, timestampInSeconds).then(r => {
          showSwappingHash(r.hash);
        });
      }else{
        console.error('factory not init');
      }
    }catch (e){
      console.error(e);
    }
  }, [swapV2Router, account, buyAmount, sellAmount, sellToken,  baseAllowance, amountsOutMin,
    baseBalance]);

    // 售卖币种改变
  useEffect(()=>{
    getSellTokenInfo().then(r => {});
  },[sellToken, buyToken, sellAmount])
  // 兑换提示
  const exchangeTip = () => {
    return (
      <div className="swap_tip">
        <p>
          This is the cost to process your transaction on the blockchain.
          Uniswap does not receive any share of these fees.
        </p>
        <a
          href="https://support.uniswap.org/hc/en-us/articles/8370337377805-What-is-a-network-cost"
          target="_blank"
        >
          Learn more
        </a>
      </div>
    );
  };

  const [setUpTip, setSetUpTip] = useState(false);
  const setTip = () => {
    return (
      <div className="set_tip">
        <div className="set_tip_title">Swap settings</div>
        <div className="set_tip_item">
          <span className="item_name">Max slippage</span>
          <div className="item_value">
            <div className="auto">Auto</div>
            <input
              type="number"
              placeholder="1.2"
              min={1.2}
              max={10}
              value={maxSlippage}
              onChange={maxSlippageChange}
              className="set_tip_input slippage_input"
            />
            <div>%</div>
          </div>
        </div>
        <div className="set_tip_item">
          <label for="time" className="item_name">
            Transaction deadline
          </label>
          <div className="item_value">
            <input
              id="time"
              type="number"
              placeholder="30"
              value={time}
              onChange={timeChange}
              className="set_tip_input time_input"
            />
            <div>minutes</div>
          </div>
        </div>
        <div className="close_btn" onClick={() => setSetUpTip(false)}>
          Close
        </div>
      </div>
    );
  };
  return (
    <div className="swap">
      <div className="swap_container">
        <div className="swap_setup">
          <Tooltip
            title={setTip}
            arrow={false}
            placement="bottomRight"
            trigger="click"
            overlayClassName="setup_tip"
            open={setUpTip}
            onOpenChange={(open) => setSetUpTip(open)}
          >
            <img src={setup} alt="" />
          </Tooltip>
        </div>
        <div
          className={
            selected ? "card card_sell card_selected" : "card card_sell"
          }
          onClick={() => setSelected(false)}
        >
          <div className="name">Sell</div>
          <div className="value">
            <div className="value_num">
              <input
                type="number"
                className="input_num"
                placeholder="0"
                value={sellAmount}
                onChange={(value)=>{
                  sellChange(value);
                }}
              />
            </div>
            <div className="value_curr">
              <TokenSelection
                tokenList={currency}
                acToken={sellToken}
                setAcToken={setSellToken}
                selectedToken={buyToken}
              />
            </div>
          </div>
          <div className="money_curr">
            {/*<div>${sellAmount * sellToken.price}</div>*/}
            {sellToken.token && (
              <div className="money_num">
                {baseBalance + ' ' + sellToken.symbol} <div className="money_max" onClick={()=>{
                  setSellAmount(baseBalance);
                }}>Max</div>
              </div>
            )}
          </div>
        </div>
        <div className="exchange" onClick={exchangeHandle}>
          <div className="exchange_box">
            <ArrowDownOutlined
              className="exchange_icon"
              style={{ fontSize: "20px", color: "rgb(34, 34, 34)" }}
            />
          </div>
        </div>
        <div
          className={
            !selected ? "card card_buy card_selected" : "card card_buy "
          }
          onClick={() => setSelected(true)}
        >
          <div className="name">Buy</div>
          <div className="value">
            <div className="value_num">
              <input
                type="number"
                className="input_num"
                placeholder="0"
                value={buyAmount}
                onChange={buyChange}
              />
            </div>
            <div className="value_curr">
              <TokenSelection
                tokenList={currency}
                acToken={buyToken}
                setAcToken={setBuyToken}
                selectedToken={sellToken}
              />
            </div>
          </div>
          {/*<div className="money_curr">${buyPrice}</div>*/}
        </div>

        <div className="btn_container">
          {
            (parseFloat(sellAmount) <= 0) ? (<div className="enter_amount">Enter an amount</div>) :
            (!(sellToken.token && buyToken.token)) ? <div className="enter_amount">Select Token</div> : <div/>
          }
          {parseFloat(sellAmount) > 0 && sellToken.token && buyToken.token && (
            <div className={isPricing ? 'reviewing' : 'review'} onClick={reviewClick}>
              {isPricing ? 'Reading Price' : 'Review'}
            </div>
          )}

          {!account && (
            <div className="link_wallet" onClick={linkWallet}>
              Connect Wallet
            </div>
          )}
        </div>
        {/*{btnStatus === 2 && (
          <div className={showMore ? "more_info more_info_show" : "more_info"}>
            <div className="info_item">
              <span className="info_name">
                1 {moreInfo.sellName} = {moreInfo.buy} {moreInfo.buyName}
              </span>
              <span
                className="info_value"
                onClick={() => setShowMore(!showMore)}
              >
                <span className="info_value_num">
                  <Tooltip
                    title={exchangeTip}
                    trigger={["hover", "click"]}
                    color="white"
                    className={
                      showMore
                        ? "info_value_tip info_value_tip_hide"
                        : "info_value_tip"
                    }
                  >
                    <img src={trade} alt="" className="trade_icon" />
                    <span>US${moreInfo.value}</span>
                  </Tooltip>
                </span>
                <img
                  src={arrow}
                  alt=""
                  className={
                    showMore ? "arrow_icon arrow_icon_show" : "arrow_icon"
                  }
                />
              </span>
            </div>
            <div className="info_item">
              <span className="info_name">
                <span>Fee (0.25%)</span>
              </span>
              <span className="info_value">
                <span className="info_value_text">
                  {moreInfo.fee} {moreInfo.symbol}
                </span>
              </span>
            </div>
            <div className="info_item">
              <span className="info_name">
                <span>Network cost</span>
              </span>
              <span className="info_value">
                <span className="info_value_text">${moreInfo.network}</span>
              </span>
            </div>
            <div className="info_item">
              <span className="info_name">
                <span>Order routing</span>
              </span>
              <span className="info_value">
                <span className="info_value_text">{moreInfo.routing}</span>
              </span>
            </div>
            <div className="info_item">
              <span className="info_name">
                <span>Price impact</span>
              </span>
              <span className="info_value">
                {moreInfo.increase ? "+" : "-"}
                {moreInfo.price}%
              </span>
            </div>
            <div className="info_item">
              <span className="info_name">
                <span>Max slippage</span>
              </span>
              <span className="info_value">
                <span className="info_value_auto">Anto</span>
                <span className="info_value_text">{moreInfo.slippage}%</span>
              </span>
            </div>
          </div>
        )}*/}
      </div>

      <Modal
        visible={confirmModal}
        onCancel={handleCancelModal}
        centered={true}
        closable={false}
        footer={null}
        className="confim_modal"
      >
        <div className="header">
          <span>You’re swapping</span>
          <img src={closureAsh} alt="" onClick={handleCancelModal} />
        </div>
        <div className="token_item">
          <div className="item_info">
            <div className="num">
              {sellAmount ? sellAmount : 0} {sellToken.symbol}
            </div>
            <div className="price">${parseFloat(sellAmount) * sellToken.price}</div>
          </div>
          <div className="item_icon">
            <img src={sellToken.icon} alt="" />
          </div>
        </div>
        <div className="token_arrow">
          <ArrowDownOutlined
            className="exchange_icon"
            style={{ fontSize: "16px", color: "#BFBFBF" }}
          />
        </div>
        <div className="token_item">
          <div className="item_info">
            <div className="num">
              {buyAmount ? buyAmount : 0} {buyToken.symbol}
            </div>
            <div className="price">${buyAmount * buyToken.price}</div>
          </div>
          <div className="item_icon">
            <img src={buyToken.icon} alt="" />
          </div>
        </div>
        {/*<div className="confim_info">
          <div className="confim_info_item">
            <span>Fee (0.25%)</span>
            <span> &lt;$0.1</span>
          </div>
          <div className="confim_info_item">
            <span>Network cost</span>
            <span> &lt;$0.1</span>
          </div>
          <div className="confim_info_item">
            <span>Rate</span>
            <span> &lt;$0.1</span>
          </div>
          <div className="confim_info_item">
            <span>Max slippage</span>
            <div className="slippage">
              <div className="auto">Auto</div>
              <span>1%</span>
            </div>
          </div>
        </div>*/}

        <div className="confim_btn" onClick={confirmExchange}>
          {parseFloat(sellAmount) > parseFloat(baseAllowance) ? 'Approve' : 'Swap'}
        </div>
      </Modal>

      <SwappingModal open={showSwappingDialog} close={() => onSwappingBack()} hash={sendSwappingHash}  back={onSwappingBack}/>
    </div>
  );
};
export default TokenSwap;

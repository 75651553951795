import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Web3 from 'web3'
import useBtcInscribe from './useBtcInscribe'

const useTransactionStatus = (transactionHash: string) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const { provider, connector, account, } = useWeb3React()

  useEffect(() => {
    if (!transactionHash || !provider) return;
    const intervalId = setInterval(async () => {
      try {
        const transactionResponse = await provider?.getTransaction(transactionHash);
        console.info('Confrorm Hash===>' + transactionHash + '==>' + transactionResponse?.confirmations);
        if (transactionResponse && transactionResponse.confirmations >= 1) {
          setIsCompleted(true);
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error checking transaction status:', error)
      }
    }, 1000);
    return () => clearInterval(intervalId)
  }, [transactionHash, account, provider])
  return isCompleted;
}

export default useTransactionStatus;

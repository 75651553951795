import React from 'react'

import './X20Footer.css'
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {darken} from "polished";
import {message} from "antd";

export default function X20Footer() {
  const activeClassName = 'active'
  const StyledNavLink = styled(NavLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.deprecated_text1};
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
  }
`
  const wailt = () => {
    message.success('coming soon')
  }

  return (

    <div className="x20footerDiv">
      <div className="x20footerLint">

        <a target="_blank" href="https://twitter.com/wedegendotcom">
          X(Twitter)
        </a>

        <a target="_blank" href="https://warpcast.com/wedegen">
          Warpcast
        </a>

        <a target="_blank" href="https://t.me/wedegendotcom">
          Telegram
        </a>

      </div>

      <div className="x20footer">
        <div className="left-copyright">
          ©wedegen.com<p>2024</p>
        </div>

        <div className="right-desc">
          This site is protected by reCAPTCHA and the Google&nbsp;
          <a href="https://www.google.com/intl/en/policies/privacy/">Privacy Policy</a> and&nbsp;
          <a href="https://www.google.com/intl/en/policies/terms/">Terms of Service</a> apply.
        </div>
      </div>
    </div>
  )
}

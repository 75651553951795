import React from 'react'
import { Row } from 'antd'
import './Overview.css'
import word_bg from '../../../assets/x20/word_bg.png'

const Overview = () => {
    return (
      <>
        <img className="overview_bg_img" src={word_bg} alt="" />
        <div className="Overview">
          <div className="title">Wedegen.com</div>

          <div className="contents">
            <div className="row space_normal">
              Pump on farcaster.
            </div>
            <div className="row space_normal">
              Three chains in one, advantages in one.
            </div>
            <div className="row space_normal">
              To create the next $SHIB token on wedegen.com.
            </div>
            <div className="row space_normal">
              The most suitable platform to launch AI social memecoin.
            </div>
            <div className="row space_normal">
              The AI ​​memecoin with the largest market cap in future should be born on a chain with AI attributes. Worldchain is naturally endowed with inherent AI attributes.
            </div>
            <div className="row space_normal">
              We have opened the Wedegen CreateToken API, which developers can use to enable token launch by AI agent.
            </div>
          </div>

          <div className="title space_normal">How it works</div>
          <div className="contents">
            <div className="row space_normal">
              Wedegen.com is actually the memecoin launchpad on the farcaster decentralized social network. All
              emissions
              are created and distributed fairly.
            </div>
            <div className="row space_normal">
              Wedegen.com, make $DEGEN, $WLD and $SHIB great, make $ETH great again or even greater.
            </div>
            <div className="row space_normal">
              On the farcaster decentralized social network, on the one hand, everyone or every AI Agent can fairly
              launch
              a social memecoin at almost zero cost, and on the other hand, anyone can contribute the equal amount of
              small liquidity to the fairly distributed social meme coin system and obtain social meme tokens emitted
              over
              time. This is a fair distribution token system similar to the "contribute to earn" system of the Bitcoin
              system.
            </div>
            <div className="row space_normal">
              Wedegen.com started as a beta version with unaudited contracts. The wedegen.com contracts were first
              deployed on Ethereum, Base, and Worldchain. In future, it will also be deployed on Arbitrum. The formal
              version will be released after being audited by an auditing company.
            </div>
            <div className="row space_normal">
              On Ethereum, you can contribute an equal amount of small liquidity by paying a small amount of $SHIB
              tokens.
              On Base, you can contribute an equal amount of small liquidity by paying a small amount of $DEGEN tokens.
              On
              Worldchain, you can contribute an equal amount of small liquidity by paying a small amount of $WLD tokens.
              Every social memecoin with a different contract is a consensus obtained by paying energy or information.
            </div>
            <div className="row space_normal">
              In fact, the wedegen.com system will inject new energy into $SHIB and $DEGEN tokens, and can bring huge
              new
              demand for $SHIB and $DEGEN tokens. $SHIB and $DEGEN tokens will generate positive impetus to promote each
              other on the wedegen.com system. $SHIB will be strongly promoted by Ethereum OGs, who are the main holders
              of $DEGEN tokens, and $DEGEN can be favored by crypto whales dominated by $SHIB holders.
            </div>
            <div className="row space_normal">
              AI memecoins are best suited to launch on WorldChain, which is a natural AI memecoin platform. Meanwhile,
              AI
              memecoins launched on Worldchain on wedegen.com are also likely to be promoted by Ethereum OG ($DEGEN core
              holders) and crypto whale capital ($SHIB core holders).
            </div>
            <div className="row space_normal">
              It is conceivable that the next $SHIB will be born on the wedegen.com system, and many super Ethereum
              (L1/L2) memecoins will emerge on the wedegen.com system. If this bull market is dominated by memecoins,
              then
              wedegen.com will make Ethereum great again.
            </div>
            <div className="row space_normal">
              For any social meme token contract created on wedegen.com, each participating address can earn the
              corresponding social meme tokens fairly over time by contributing an equal amount of small liquidity.
            </div>
            <div className="row space_normal">
              For each social token contract, the number of addresses participating in the equal amount of small
              liquidity
              contributions is capped at 100k, that is, when the number of participating addresses exceeds the set
              maximum
              number of 100k, subsequent new addresses will not be able to participate.
            </div>
            <div className="row space_normal">
              The equal amount of small liquidity contribution mechanism ensures fairness, and there will be no early
              advantages or large capital advantages like DeFi yield farming.
            </div>
            <div className="row space_normal">
              At the same time, as the price of the benchmark token $SHIB (on Ethereum) or $DEGEN (on Base) or $WLD (on
              WorldChain) increases, the cost for participants to obtain memecoins will gradually increase, that is, the
              difficulty of producing the corresponding tokens will also increase accordingly. Similar to Bitcoin's POW
              mechanism, the social memecoins on wedegen.com are all fairly created based on the equal amount of small
              liquidity contribution mechanism, and token rewards are distributed over time.
            </div>
            <div className="row space_normal">
              The ownerships of all social meme token contracts are burned, that is, all social memecoins are ownerless
              tokens.
            </div>
            <div className="row space_normal">
              All contributors'LPs are automatically transferred into the black hole, and all contributors will never be
              able to withdraw their liquidity.
            </div>
            <div className="row space_normal">
              For each social token contract, an address can only participate in the contribution once. Of course, an
              address can participate in the "earning" of multiple different social token contracts and receive
              corresponding token rewards over time.
            </div>
            <div className="row space_normal">
              Invitation Rewards: for both the inviters and the invitees, only the addresses that participate in equal
              amount of small liquidity contributions will have inviter rewards and invitee rewards, that is, their
              wallet
              addresses must pay 500 $DEGEN or 500k $SHIB or 2 $WLD. Addresses that do not pay 500 $DEGEN tokens or 500k
              $SHIB tokens or 2 $WLD tokens will have no inviter rewards or invitee rewards. Both inviter rewards and
              invitee rewards are emitted during the first halving cycle.
            </div>
            <div className="row space_normal">
              On Ethereum, participants need to have at least 500k $SHIB tokens on their wallet addresses. When the
              address participates in earning, 500k $SHIB tokens on the address will be automatically deducted as a
              small
              liquidity contribution.
            </div>
            <div className="row space_normal">
              All the participating addresses need to pay the same amount of 500k $SHIB tokens as liquidity
              contribution.
              All the participating addresses ensure fair distribution of social meme tokens by contributing equal
              amounts
              of small liquidity.
            </div>
            <div className="row space_normal">
              As the price of $SHIB increases, the cost for subsequent participants will increase, which means that the
              difficulty of producing the corresponding social memecoins will also increase. This is similar to the
              increasing difficulty of Bitcoin production.
            </div>
            <div className="row space_normal">
              On Base, participants need to have at least 500 $DEGEN tokens on their wallet addresses. When the address
              participates in earning, 500 $DEGEN tokens on the address will be automatically deducted as a small
              liquidity contribution.
            </div>
            <div className="row space_normal">
              All the participating addresses need to pay the same amount of 500 $DEGEN tokens as liquidity
              contribution.
              All the participating addresses ensure fair distribution of social meme tokens by contributing equal
              amounts
              of small liquidity.
            </div>
            <div className="row space_normal">
              As the price of $DEGEN increases, the cost for subsequent participants will increase, which means that the
              difficulty of producing the corresponding social memecoins will also increase. This is similar to the
              increasing difficulty of Bitcoin production.
            </div>
            <div className="row space_normal">
              On WorldChain, participants need to have at least 2 $WLD tokens on their wallet addresses. When the
              address
              participates in earning, 2 $WLD tokens on the address will be automatically deducted as a small liquidity
              contribution.
            </div>
            <div className="row space_normal">
              All the participating addresses need to pay the same amount of 2 $WLD tokens as liquidity contribution.
              All
              the participating addresses ensure fair distribution of social meme tokens by contributing equal amounts
              of
              small liquidity.
            </div>
            <div className="row space_normal">
              As the price of $WLD increases, the cost for subsequent participants will increase, which means that the
              difficulty of producing the corresponding social memecoins will also increase. This is similar to the
              increasing difficulty of Bitcoin production.
            </div>
            <div className="row space_normal">
              When the number of addresses participating in the contributions reaches 100, that is, a liquidity pool
              will
              be automatically generated and token trading can be started at this time.
            </div>
            <div className="row space_normal">
              Before the number of addresses that have contributed the equal amount of small liquidity reaches 100, the
              token creator can cancel, that is, burn the issued tokens. At the same time, all addresses that have
              contributed the equal amount of small liquidity will receive 90% refund of the $SHIB or $DEGEN or $WLD
              paid,
              and the system will deduct 10% of the $SHIB or $DEGEN or $WLD tokens paid as a refund tax.
            </div>
            <div className="row space_normal">
              Only the token creator has the power to cancel (burn) the tokens he created.
            </div>
            <div className="row space_normal">
              When the number of addresses that have contributed the equal amount of small liquidity is greater than
              100,
              you will not be able to burn the tokens you created, that is, you will not be able to click the burn
              confirmation button. Of course, if you have already burned the tokens you created, you will not be able to
              click the burn confirmation button again.
            </div>
            <div className="row space_normal">
              When the number of participating contributing addresses is greater than 100, each address pays 500k $SHIB
              tokens or 500 $DEGEN tokens or 2 $WLD tokens, and the system will automatically add the 500k $SHIB tokens
              or
              500 $DEGEN tokens or 2 $WLD tokens that have already paid and the corresponding number of social meme
              tokens
              to the liquidity pool. At the same time, the LP will automatically be transferred to the black hole, and
              no
              one can withdraw the liquidity tokens anymore, that is, no contributor can remove the small amount of
              liquidity they contributed.
            </div>
            <div className="row space_normal">
              Once the liquidity pool is generated, the price of social meme tokens will fluctuate. Not every address
              adds
              500k social meme tokens to the liquidity pool (of course, for each address, the corresponding amount of
              500k
              $SHIB tokens or 500 $DEGEN tokens or 2 $WLD tokens added to the liquidity pool must be the same.), and the
              number of social meme tokens emitted by each address over time is 500k. Once a liquidity pool is
              generated,
              if less than 500k social meme tokens are added to the pool, the remaining will be burned, that is, no
              matter
              what, for each participating address, the number of social meme tokens corresponding to the address is
              500k
              (for adding liquidity and being burned) + 500k (emitted over time within 16 years).
            </div>
            <div className="row space_normal">
              The upper limit of the number of contributing addresses for each social meme token contract is 100k, and
              each participating address corresponds to 1 million social meme tokens, that is, the hard cap of social
              tokens for each different contract is 100 billion.
            </div>
            <div className="row space_normal">
              This is a token distribution emission mechanism based on equal small liquidity contributions, rather than
              DeFi yield farming.
            </div>
            <div className="row space_normal">
              The transaction tax for the social meme tokens created on wedegen.com totals 1.2%, of which 0.3% is
              burned,
              0.3% is converted into $SHIB tokens (on Ethereum) or $DEGEN tokens (on Base) or $WLD tokens (on
              WorldChain)
              and automatically transferred to the creator's address, 0.3% is converted into $SHIB tokens (on Ethereum)
              or
              $DEGEN tokens (on Base) or $WLD tokens (on WorldChain) and automatically transferred to Vitalik's ENS
              address as a Vitalik charity memecoin fund, and another 0.3% is converted into $SHIB tokens (on Ethereum)
              or
              $DEGEN tokens (on Base) or $WLD tokens (on WorldChain) and transferred to two official addresses of
              wedegen.com, one of which receives 0.015% and the other receives 0.285%.
            </div>
            <div className="row space_normal">
              The above four fees are automatically executed when the user sells; when the user buys, only the burning
              fee
              is executed, and the other three fees will be combined with the next sale when other users or the user
              sells.
            </div>
            <div className="row space_normal">
              When selling, 0.3% of the tokens in the 1.2% tax will be burned first. Then, when the remaining 0.9% is
              greater than 1 token, the corresponding 0.3%-0.3%-0.3%（0.285%+0.015%） will be converted into $DEGEN or
              $SHIB
              or $WLD, and then transferred to another 4 addresses. If the remaining 0.9% is less than 1 token, it will
              be
              accumulated until the next sale. When the 0.9% is greater than 1 token at the next sale, it will be
              automatically converted and transferred to another 4 addresses.
            </div>
            <div className="row space_normal">
              When buying or selling, it is best to do it on the official wedegen.com website, where users only pay 1.2%
              transaction tax.
            </div>
            <div className="row space_normal">
              When buying or selling on Uniswap, please set the slippage to be greater than 1.2%, such as 1.25%. Due to
              the front problem of Uniswap, if the transaction fails, you need to refresh the page before you can buy
              and
              sell again. It should be noted that in addition to the 1.2% transaction tax of Wedegen mentioned above,
              Uniswap front will also charge transaction fees. The total transaction cost of users will not exceed 1.5%.
              In addition, due to Uniswap's front problems, when exchanging tokens on WorldChain, Uniswap does not
              display the number of exchanged tokens.
            </div>
            <div className="row space_normal">
              Invitation Rewards: both the inviters and the invitees have inviter rewards and invitee rewards only for
              the
              addresses that participate in equal small liquidity contributions (i.e. paying $SHIB on Ethereum, paying
              $DEGEN on Base and paying $WLD on WorldChain).
            </div>
            <div className="row space_normal">
              $SHIB and $DEGEN will become Vitalik's charity memecoins, just like $DOGE became Elon's memecoin.
            </div>
            <div className="row space_normal">
              It may take a little longer (usually just a few seconds at most) to go to the social meme token page or
              invitation link page on the wedegen.com official website, so please be patient.
            </div>

            <div className="row space_normal">
              After the latest testing, the actual gas fee required to create tokens on the Ethereum mainnet is high
              (hundreds of dollars). If you want to launch a meme coin on the Ethereum mainnet, please pay attention to
              the creation cost. In addition, please note that the 100th address that contributes a small amount of
              liquidity on Ethereum will require a higher amount of gas (sometimes more than a hundred dollars) when
              paying 500k $SHIB, because the 100th address also undertakes the work of creating a liquidity pool. On
              Base and Worldchain, this gas is very small and can be ignored.
            </div>
          </div>

          <div className="title">Give an example to illustrate</div>
          <div className="contents">
            <div className="row space_normal">
              Now, we "assume" that someone creates XXX token on Base and explain it in detail with an example.
            </div>
            <div className="row space_normal">
              Token Name: XXX
              Token Symbol: XXX
              Hard cap: 100 billion
            </div>
            <div className="row space_normal">
              When a participant pays 500 $DEGEN, the system will automatically add an equal amount of small liquidity
              (that is, 500 $DEGEN tokens and the corresponding number of XXX tokens), and the LP will automatically be
              transferred to the black hole. XXX will be emitted fairly over time, halved every 4 years, and it will take
              16 years to complete the emission.
            </div>
            <div className="row space_normal">
              Each address can only participate in the contribution once. When the critical point of 100 participating
              addresses is reached, a liquidity pool will be automatically generated and XXX/DEGEN can be traded.
            </div>
            <div className="row space_normal">
              Each participating address will receive a total of 500k token rewards over 16 years, with a halving cycle of 4 years.
            </div>
            <div className="row space_normal">
              1）Before participants reach the critical number of addresses to automatically generating a liquidity pool, 500 $DEGEN tokens and 500k XXX tokens per address will be added to the liquidity pool formed in future.
            </div>
            <div className="row space_normal">
              2）After participants reach the critical number of addresses to automatically generating the liquidity pool, 500 $DEGEN tokens per address and the corresponding number of XXX tokens corresponding to the price of XXX tokens at this time are automatically added to the liquidity pool. The LP will be automatically burned, and no one will be able to remove the small amount of liquidity they contributed.
            </div>
            <div className="row space_normal">
              All participants must have at least 500 $DEGEN tokens on their addresses. An address can only participate once. The maximum number of addresses that can participate in the same amount of small liquidity contribution is 100k.
            </div>
            <div className="row space_normal">
              Invitation rewards: regardless of the inviter or the invitee, only the address that participates in the same amount of small liquidity contribution will receive the inviter rewards or the invitee rewards.
            </div>
            <div className="row space_normal">
              That is, only the address that pays 500 $DEGEN tokens can receive either the inviter rewards or the invitee rewards. For the address that does not pay 500 $DEGEN tokens, there is no inviter rewards or invitee rewards. The inviter rewards and invitee rewards are all emitted in the first halving cycle.
            </div>
            <div className="row space_normal">
              A wallet address that has paid 500 $DEGEN tokens invites 1 address. When the invitee's address has also paid 500 $DEGEN tokens, the inviter's rewards are 5k XXX tokens (5k XXX tokens are emitted within the first halving cycle, that is, they are emitted within 4 years), and the invitee's rewards are 2.5k tokens (also emitted within the first halving cycle, that is, they are emitted within 4 years).
            </div>
            <div className="row space_normal">
              You only need to click on the corresponding page to copy your invitation link to the invitees.
            </div>
          </div>
        </div>
      </>
    )
}

export default Overview

import BigNumber from 'bignumber.js'

// export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
//   const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
//   return displayBalance.toNumber()
// }

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  // 先将数值除以10的幂次，然后格式化成字符串，不进行任何舍入
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(decimals, BigNumber.ROUND_DOWN);
}

export const getBalanceNumber2 = (balance: string, decimals = 18) => {
  // const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  return new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals)).toFixed(decimals, BigNumber.ROUND_DOWN);

  // return displayBalance.toNumber()
}

// export const getBalanceNumber3 = (balance: string, decimals = 18) => {
//   const displayBalance = new BigNumber(balance).mul(0.95).dividedBy(new BigNumber(10).pow(decimals))
//   return displayBalance.toNumber().toFixed(0);
// }

export const getBalanceNumber3 = (balance: string, slippage = 1.5): string => {
  const rate = (new BigNumber(100).sub(new BigNumber(slippage))).dividedBy(new BigNumber(100));
  console.info("rate=" + rate);
  const displayBalance = new BigNumber(balance)
    .mul(rate)
    .divToInt(BigNumber.ROUND_DOWN);
  return displayBalance.toFixed(0);
};

export const toBigString = (balance: string, decimals = 18) => {
  const displayBalance = new BigNumber(balance).mul(new BigNumber(10).pow(decimals))
  return displayBalance.toString();
}


export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}


export const getMinBalance = (balance: BigNumber, decimals = 18, length=9) => {
  const x = balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(length).toString();
   if(x.indexOf(".")>=0){
     return x.substring(0, x.length - 1);
   }
  return x;
}


export const getShowBalance = (balance: BigNumber, length=9) => {
  const x = balance.toFixed(length).toString();
  if(x.indexOf(".")>=0){
    return x.substring(0, x.length - 1);
  }
  return x;
}

export function formatCurrency(amount: string) {
  // // 将数字分为整数部分和小数部分
  // let [integerPart, decimalPart] = new BigNumber(amount).toFixed(8).split('.');
  // // 添加千分位分隔符
  // integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // // 拼接整数和小数部分
  // return `$${integerPart}.${decimalPart}`;
  // amount = '1000000.12345678';
  if(!amount)return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
    useGrouping: true
  }).format(parseFloat(amount));
}

export function formatCurrency2(amount: string) {
  return "("+formatCurrency(amount)+")";
}




import { FeatureFlag, useUpdateFlag } from 'featureFlags'
import { Phase0Variant, usePhase0Flag } from 'featureFlags/flags/phase0'
import { Phase1Variant, usePhase1Flag } from 'featureFlags/flags/phase1'
import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react'
import {AlertCircle, CheckCircle, X} from 'react-feather'
import { useModalIsOpen, useToggleFeatureFlags } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import useTheme from 'hooks/useTheme'
import styled, {keyframes, ThemeContext} from 'styled-components/macro'
import Loader from "../Loader";
import {message} from "antd";
import { NFT_TO_BTC_UTILS} from "../../constants/addresses";
import {useWeb3React} from "@web3-react/core";
import {useNftToBtcUtils} from "../../hooks/useContract";
import useBtcInscribe from "../../hooks/useBtcInscribe";
import {ButtonPrimary} from "../Button";
import {ThemedText} from "../../theme";
import {useTransaction} from "../../state/transactions/hooks";
import useTransactionStatus from '../../hooks/useTransactionStatus'

const StyledModal = styled.div`
  position: fixed;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: fit-content;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 18px;
  padding: 20px;
  background-color: ${({ theme }) => theme.backgroundSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  z-index: 100;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

function Modal({ open, children }: { open: boolean; children: ReactNode }) {
  return open ? <StyledModal>{children}</StyledModal> : null
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
`

const CloseButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.none};
  border: none;
  color: ${({ theme }) => theme.textPrimary};
`

const Header = styled(Row)`
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-bottom: 8px;
`


function FeatureFlagOption({
  label,
}: {
  label: string
}) {
  return (
    <Row>
        <div>Please wait for the transaction confirmation, 1 minute later you can fresh the page to get new data.</div>
    </Row>
  )
}

interface TwtProps {
    open: boolean;
    hash: string;
    close: () => void;
    back: () => void;
}


export default function SwappingModal({
                                            open,
                                            hash,
                                            close,
                                            back
                                        }: TwtProps) {
    const theme = useContext(ThemeContext)

    const isCompleted = useTransactionStatus(hash);

    return (
        <Modal open={open}>
            <Header>
              {isCompleted ? 'Transaction successful' : 'Wait for confirmation'}
                <CloseButton onClick={close}>
                    <X size={24}/>
                </CloseButton>
            </Header>
            {/*<AlertCircle color={theme.deprecated_red1} size={24} />*/}

            <FeatureFlagOption label={"1"}/>


            <div style={{paddingRight: 16, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {isCompleted ? (
                    <CheckCircle color={theme.deprecated_green1} size={'14rem'}/>
                ) : (
                    <Loader size={'14rem'}/>
                )}
            </div>


            {/*<ButtonPrimary onClick={close}>
                <ThemedText.DeprecatedMediumHeader color="white">
                    Close And Refesh
                </ThemedText.DeprecatedMediumHeader>
            </ButtonPrimary>*/}

        </Modal>
    )
}
